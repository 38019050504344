import { PageWithParent } from '../../../../models/page-with-parent.type';
import { dirContents } from '../../ls';
import { resolvePath } from '../resolvePath';

export function fileCompletions(
  argToComplete: string,
  cwd: PageWithParent,
  includeFiles = true,
  includeHidden: 'always' | 'never' | 'matching' = 'matching'
) {
  const contextIndex = argToComplete.lastIndexOf('/');
  const context =
    contextIndex > -1 ? argToComplete.substring(0, contextIndex + 1) : '';
  const fileName =
    contextIndex > -1
      ? argToComplete.substring(contextIndex + 1)
      : argToComplete;
  const filterHidden =
    includeHidden === 'never' ||
    (includeHidden === 'matching' && !fileName.startsWith('.'));

  let contents = dirContents(resolvePath(cwd.path, context));
  if (!contents) {
    return [];
  }
  if (!includeFiles) {
    contents = contents.filter((content) => content.type === 'dir');
  }
  if (filterHidden) {
    contents = contents.filter((content) => !content.name.startsWith('.'));
  }
  return contents.map((content) => {
    return `${context}${content.name}${content.type === 'dir' ? '/' : ' '}`;
  });
}
