import type { Command } from './index';

import * as cmds from './index';

export const help: Command = {
  execute(args) {
    const cmd = args[0] as keyof typeof cmds;
    if (!cmd) {
      return {
        out: [
          'Usage: help «command»',
          'Available commands:',
          ...Object.keys(cmds),
        ].map((text) => ({ text, stream: 'err' })),
        status: 1,
      };
    }
    if (!(cmd in cmds)) {
      return {
        out: [
          {
            text: `No help available for ${cmd} or not a valid command`,
            stream: 'err',
          },
        ],
        status: 2,
      };
    }
    return {
      out: cmds[cmd].help.map((text) => ({ text })),
    };
  },
  completionOptions(args, _argToComplete, _cwd, _variables) {
    if (args.length) {
      // Only one arg supported, not completing another one
      return [];
    }
    return Object.keys(cmds).map((cmd) => `${cmd} `);
  },
  help: ['help yourself'],
};
