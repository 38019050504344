export const Logo: React.FC = () => {
  return (
    <svg
      className="logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31.96 40"
    >
      <polygon
        className={`logo--shape`}
        points="22.26 25 31.96 7.99 23.96 7.99 18.26 17.98 8 0 0 0 14.26 25 5.7 40 13.7 40 18.26 32.01 26.26 32.01 22.26 25"
      />
    </svg>
  );
};
