import { Page } from '../models/page';
import { PageWithParent } from '../models/page-with-parent.type';
import { SiteDataJson } from '../models/site-data.type';

export function preparePageTree(data: SiteDataJson): PageWithParent {
  return pageWithParent(data.home);
}

function pageWithParent(page: Page, parent?: PageWithParent): PageWithParent {
  const result: PageWithParent = {
    ...page,
    parent,
    path: `/${page.pathSegments.join('/')}`,
    children: [],
  };
  result.children = page.children.map((child) => pageWithParent(child, result));

  return result;
}
