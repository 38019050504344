import {
  randomEasingFunc,
  randomFillColor,
  randomIntBetween,
} from './draw.utlis';
import { easeInBack, easeInCubic } from './easeing';
import {
  MouseInteractionType,
  Renderable,
} from './models/Renderable.interface';

export class Bubble implements Renderable {
  private fillStyle!: string | CanvasGradient | CanvasPattern;
  private radiusY: number = 0;
  private radiusX: number = 0;
  private originalRadius: number = 0;
  private x: number = 0;
  private y: number = 0;
  private startTime: number = 0;
  private duration: number = 4000;
  private easingFunc: (x: number) => number = (x: number) => x;
  private gotHitTime: number | null = null;
  private isDestroying: number = -1;
  private burstRadius: number = 0;
  private burstDuration: number = 160;
  private burstX: number = 0;
  private burstY: number = 0;
  private bottomMargin: number = 20;
  private startTimeOffset: number = 0;

  constructor(
    stageHeight: number,
    stageWidth: number,
    startTimeOffset?: number
  ) {
    this.setup(stageHeight, stageWidth, startTimeOffset);
  }

  public isOutOfBounds(): boolean {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - this.startTime;
    return elapsedTime >= this.duration;
  }

  public justGotHit(): boolean {
    return this.gotHitTime !== null && this.isDestroying === -1;
  }

  public shouldBePruned(): boolean {
    return this.isOutOfBounds() || this.isDestroying == 1;
  }

  public destroy(): void {}

  public handleMouseInteraction(
    type: MouseInteractionType,
    mouseX: number,
    mouseY: number
  ): void {
    if (type === 'mousedown') {
      this.hitTest(mouseX, mouseY);
    }
  }

  public setup(
    stageHeight: number,
    stageWidth: number,
    startTimeOffset: number = 0
  ): void {
    this.fillStyle = randomFillColor();
    this.radiusY = randomIntBetween(40, Math.min(230, stageWidth * 0.15));
    this.radiusX = this.radiusY;
    this.originalRadius = this.radiusY;
    this.x = randomIntBetween(0, stageWidth);
    this.duration = randomIntBetween(8000, 16000);
    this.easingFunc = randomEasingFunc();
    this.y = stageHeight + this.radiusY + this.bottomMargin;
    this.burstDuration = Math.max(80, this.burstDuration * Math.random());
    this.startTime = new Date().getTime();
    this.startTimeOffset = startTimeOffset;
    this.bottomMargin = randomIntBetween(10, 60);
  }

  public draw(ctx: CanvasRenderingContext2D): void {
    this.prepareNextBubblePosition(ctx.canvas.height);

    ctx.shadowColor = 'rgba(0,0,0,0.2)';
    ctx.shadowBlur = 8;
    ctx.fillStyle = this.fillStyle;
    ctx.beginPath();
    ctx.ellipse(
      this.x,
      this.y,
      this.radiusX,
      this.radiusY,
      0,
      2 * Math.PI,
      0,
      false
    );
    if (this.gotHitTime) {
      this.prepareDestruction();
      const randomOffsetX = (Math.random() - 0.5) * this.originalRadius * 0.4;
      const randomOffsetY = (Math.random() - 0.5) * this.originalRadius * 0.4;
      ctx.arc(
        this.burstX + randomOffsetX,
        this.burstY + randomOffsetY,
        this.burstRadius,
        0,
        2 * Math.PI,
        true
      );
    }
    ctx.fill();
  }

  private prepareNextBubblePosition(stageHeight: number): void {
    const currentTime = new Date().getTime() + this.startTimeOffset;
    const elapsedTime = currentTime - this.startTime;

    if (elapsedTime >= this.duration) {
      return;
    }

    const totalDistance = stageHeight + this.bottomMargin + this.radiusY * 4;
    const unscaledValue = this.easingFunc(elapsedTime / this.duration);
    this.y =
      stageHeight +
      this.bottomMargin +
      this.radiusY -
      totalDistance * unscaledValue;
  }

  private prepareDestruction(): void {
    const elapsedTime = new Date().getTime() - this.gotHitTime!;

    if (elapsedTime >= this.burstDuration) {
      this.isDestroying = 1;
      return;
    }

    const percentElapsed = elapsedTime / this.burstDuration;
    this.radiusY =
      this.originalRadius +
      this.originalRadius * 0.4 * easeInBack(percentElapsed);
    this.burstRadius = this.originalRadius * 1.2 * easeInCubic(percentElapsed);
  }

  private hitTest(x: number, y: number): void {
    if (!this.gotHitTime && this.isPointInsideBubble(x, y)) {
      this.burstX = x;
      this.burstY = y;
      this.gotHitTime = new Date().getTime();
    }
  }

  private isPointInsideBubble(x: number, y: number): boolean {
    const midX = this.x;
    const midY = this.y;
    const distPoints = (x - midX) ** 2 + (y - midY) ** 2;

    return distPoints <= this.radiusY ** 2;
  }
}
