import { useContext } from 'react';
import { ModeContext } from '../context/mode';
import { FunLayout } from './fun/FunLayout';
import { PlainLayout } from './plain/PlainLayout';
import { TechLayout } from './tech/TechLayout';

export const Layout: React.FC = () => {
  const [mode] = useContext(ModeContext);

  let modeLayout: JSX.Element;
  switch (mode) {
    case 'FUN':
      modeLayout = <FunLayout></FunLayout>;
      break;
    case 'TECH':
      modeLayout = <TechLayout></TechLayout>;
      break;
    case 'PLAIN':
    default:
      modeLayout = <PlainLayout></PlainLayout>;
  }

  return modeLayout;
};
