import { Bubble } from './Bubble';
import { randomIntBetween } from './draw.utlis';
import { Scene } from './models/Scene.interface';

export class BubbleScene implements Scene {
  private bubbles: Bubble[] = [];
  private isAnimating: boolean = false;
  private ctx: CanvasRenderingContext2D | null = null;
  private creationTimeout: number | null = null;

  private get context(): CanvasRenderingContext2D {
    if (!this.ctx) {
      throw new Error(
        'Context is not set in scene. Make sure to run setup first.'
      );
    }
    return this.ctx;
  }

  private get canvas(): HTMLCanvasElement {
    return this.context.canvas;
  }

  public draw(): void {
    this.clearScene();
    this.prepareNextFrame();

    for (let bubble of this.bubbles) {
      bubble.draw(this.context);
    }

    if (this.isAnimating) {
      window.requestAnimationFrame(() => this.draw());
    }
  }

  public destroy(): void {
    this.stop();
    this.canvas.removeEventListener('click', this.handleMouseDown.bind(this));
    this.clearCreationTimeout();

    for (let renderable of this.bubbles) {
      renderable.destroy();
    }
    this.bubbles = [];
  }

  public prepareNextFrame(): void {
    this.pruneBubblesOutOfBounds();
  }

  public setup(context: CanvasRenderingContext2D): void {
    this.ctx = context;
    this.canvas.addEventListener('mousedown', this.handleMouseDown.bind(this));

    const randomAmountOfStartingBubbles = randomIntBetween(4, 15);
    for (let i = 0; i < randomAmountOfStartingBubbles; i += 1) {
      this.createNewBubble(randomIntBetween(1000, 2500));
    }
  }

  public start(): void {
    this.isAnimating = true;
    this.startCreationTimeout();
    this.draw();
  }

  public stop(): void {
    this.isAnimating = false;
    this.clearCreationTimeout();
  }

  private startCreationTimeout(): void {
    this.creationTimeout = setTimeout(() => {
      this.createNewBubble();
      this.startCreationTimeout();
    }, (Math.random() * 200000) / this.canvas.width + 100);
  }

  private clearCreationTimeout(): void {
    if (this.creationTimeout) {
      clearTimeout(this.creationTimeout);
    }
  }

  private createNewBubble(startTimeOffset?: number): void {
    const bubble: Bubble = new Bubble(
      this.canvas.height,
      this.canvas.width,
      startTimeOffset
    );
    const randomIndex = Math.floor(Math.random() * this.bubbles.length);
    this.bubbles.splice(randomIndex, 0, bubble);
  }

  private clearScene(): void {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  private handleMouseDown(event: MouseEvent): void {
    for (let i = this.bubbles.length - 1; i >= 0; i--) {
      const bubble = this.bubbles[i]!;
      bubble.handleMouseInteraction('mousedown', event.offsetX, event.offsetY);
      if (bubble.justGotHit()) {
        break;
      }
    }
  }

  private pruneBubblesOutOfBounds(): void {
    this.bubbles = this.bubbles.filter((renderable) => {
      // TODO: could potentially be removed
      if (renderable.shouldBePruned()) {
        renderable.destroy();
      }

      return !renderable.shouldBePruned();
    });
  }
}
