import { pages } from '../../context/application';
import { resolvePath } from './helpers';
import { fileCompletions } from './helpers/completion/fileCompletions';
import { Command } from './index';

export const cd: Command = {
  execute(args, cwd) {
    const route = args[0] || '/';

    const newRoute = resolvePath(cwd.path, route);

    if (!pages.has(newRoute)) {
      return {
        out: [{ text: `cd: no such directory ${route}`, stream: 'err' }],
        status: 1,
      };
    }

    return {
      newRoute,
    };
  },
  completionOptions(args, argToComplete, cwd) {
    if (args.length) {
      // Only one arg supported, not completing another one
      return [];
    }
    return fileCompletions(argToComplete, cwd, false);
  },
  help: ['cd: change directory', 'usage: cd [newdir]'],
};
