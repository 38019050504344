import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageWithParent } from '../models/page-with-parent.type';

import { splitArgs } from '../data/commands/helpers';

import type { Command, CommandResult } from '../data/commands';

import * as cmds from '../data/commands';

import { OutputLine } from './useAppendLine';
import { ModeContext } from '../context/mode';

const COMMANDS = cmds as Record<string, Command>;

export function useHandleCommand(
  cwd: PageWithParent,
  variables: Record<string, string>,
  appendLines: (...line: OutputLine[]) => void,
  setVariables: React.Dispatch<React.SetStateAction<Record<string, string>>>
) {
  const navigate = useNavigate();
  const [, setMode] = useContext(ModeContext);

  function handleCommand(command: string) {
    const [cmd, ...args] = splitArgs(command, variables);
    let actions: CommandResult;
    if (!cmd) {
      // Do nothing
      return;
    }
    if (cmd.includes('=')) {
      // Set variable
      let [varName, ...value] = cmd.split('=');
      value = [...value, ...args];
      setVariables((variables) => ({
        ...variables,
        [varName!]: value.join(' '),
      }));
      return;
    }
    if (!(cmd in COMMANDS)) {
      actions = {
        out: [{ text: `${cmd}: not found`, stream: 'err' }],
        status: -1,
      };
    } else {
      actions = COMMANDS[cmd]!.execute(args, cwd, variables);
    }

    if (actions.newRoute) {
      navigate(actions.newRoute);
    }
    if (actions.out) {
      appendLines(...actions.out.map((line) => ({ ...line, cmd })));
    }
    if (actions.newMode) {
      setMode(actions.newMode);
    }
    setVariables((vars) => ({ ...vars, '?': String(actions.status || 0) }));
  }

  function completeCommand(command: string): string | undefined {
    let completionOptions: string[] = [];
    const wantsNewArg = /\s$/.test(command);
    const args = splitArgs(command, variables);
    const argToComplete = wantsNewArg ? '' : args.pop() ?? '';

    if (args.length) {
      // Complete arguments, not command
      const cmd = args.shift()!;
      if (cmd in COMMANDS) {
        completionOptions =
          COMMANDS[cmd]!.completionOptions?.(
            args,
            argToComplete,
            cwd,
            variables
          ) ?? [];
      }
    } else {
      // Complete command name
      completionOptions = Object.keys(COMMANDS).map((cmdName) => `${cmdName} `);
    }
    // Prefix-match the options (commands are encouraged, but not required, to do this beforehand)
    completionOptions = completionOptions.filter((completion) =>
      completion.startsWith(argToComplete)
    );
    if (completionOptions.length === 1) {
      // Found a single match. Return its completion.
      return completionOptions[0]!.substring(argToComplete.length);
    }
    if (completionOptions.length) {
      // Found multiple matches, print them to the screen.
      appendLines({
        text: completionOptions.join(' '),
        completion: true,
      });
    }
    return undefined;
  }

  return [handleCommand, completeCommand] as const;
}
