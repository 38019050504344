import { useEffect, useRef } from 'react';
import { OutputLine } from '../../hooks/useAppendLine';

const Line: React.FC<{ line: OutputLine }> = ({ line }) => {
  const stream = line.stream ?? 'out';

  const className = [
    stream,
    line.cmd && 'cmd-' + line.cmd,
    line.completion && 'completion',
  ]
    .filter(Boolean)
    .join(' ');

  if (line.html) {
    return (
      <span
        className={className}
        dangerouslySetInnerHTML={{ __html: line.html + '\n' }}
      ></span>
    );
  }

  return (
    <span className={className}>
      {line.text}
      {'\n'}
    </span>
  );
};

export const Output: React.FC<{ lines: OutputLine[] }> = ({ lines }) => {
  const outputRef = useRef<HTMLOutputElement>(null);

  useEffect(() => {
    if (outputRef.current) {
      outputRef.current!.scrollTop = outputRef.current!.scrollHeight;
    }
  }, [lines]);

  return (
    <output ref={outputRef}>
      {lines.map((line, i) => (
        <Line line={line} key={i} />
      ))}
    </output>
  );
};
