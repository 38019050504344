import { NavLink, useLoaderData } from 'react-router-dom';
import { mainNavigation, siteInfo } from '../../context/application';
import { PageWithParent } from '../../models/page-with-parent.type';
import { Navigation } from './Navigation';

export const PlainLayout = () => {
  const tagLineContent = siteInfo['tag-line'].content;
  const pageData = useLoaderData() as PageWithParent;

  return (
    <>
      <div
        className="tag-line"
        dangerouslySetInnerHTML={{ __html: tagLineContent }}
      ></div>
      <Navigation navItems={mainNavigation} />
      <div
        role="main"
        className={`content ${getPageStyle(pageData.pathSegments)}`}
      >
        <div dangerouslySetInnerHTML={{ __html: pageData.content }}></div>
        {conditionallyRenderWorkList(pageData)}
      </div>
    </>
  );
};

function getPageStyle(pathSegments: string[]): string {
  const [topLevelSegment, secondLevelSegment] = pathSegments;
  if (!topLevelSegment) {
    return '';
  }
  const classList = [topLevelSegment];
  if (topLevelSegment === 'works' && !!secondLevelSegment) {
    classList.push('works--detail');
  }

  return classList.join(' ');
}

function conditionallyRenderWorkList(pageData: PageWithParent): JSX.Element {
  if (pageData.path !== '/works') {
    return <></>;
  }

  return (
    <ul className="work-list">
      {pageData.children.map((childPage) => {
        const heroContent = childPage.additionalFields['hero']?.content;
        if (!heroContent) {
          return;
        }

        return (
          <li key={childPage.path}>
            <NavLink
              to={childPage.path}
              dangerouslySetInnerHTML={{ __html: heroContent }}
            ></NavLink>
          </li>
        );
      })}
    </ul>
  );
}
