import { pages } from '../../context/application';
import { OutputLine } from '../../hooks/useAppendLine';
import { resolvePath } from './helpers';
import { fileCompletions } from './helpers/completion/fileCompletions';
import { Command } from './index';
import { dirContents } from './ls';

export const cat: Command = {
  execute(args, cwd) {
    if (!args.length) {
      return {
        code: 1,
        out: [{ text: this.help[1]!, stream: 'err' }],
      };
    }

    const out: OutputLine[] = [];

    for (const path of args) {
      const absolutePath = resolvePath(cwd.path, path);
      if (pages.has(absolutePath)) {
        out.push({ text: `cannot cat ${path}: is a directory`, stream: 'err' });
        continue;
      }
      const slashPos = absolutePath.lastIndexOf('/');
      const dirName = absolutePath.substring(0, slashPos) || '/';
      const fileName = absolutePath.substring(slashPos + 1);
      const contents = dirContents(dirName) ?? [];
      const file = contents.find((item) => item.name === fileName);
      if (!file || file.type !== 'file') {
        out.push({ text: `${path}: no such file or directory`, stream: 'err' });
        continue;
      }
      if (args.length > 1) {
        out.push({ text: `${path}:` });
      }
      out.push({ html: file.contents });
      if (args.length > 1) {
        out.push({ text: '' });
      }
    }

    return { out };
  },
  completionOptions(_args, argToComplete, cwd) {
    return fileCompletions(argToComplete, cwd);
  },
  help: ['cat: list a file contents', 'usage: cat [file...]'],
};
