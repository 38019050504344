export function resolvePath(cwd: string, path: string) {
  if (path.startsWith('/')) {
    cwd = '';
  }
  if (!cwd.endsWith('/')) {
    cwd += '/';
  }
  cwd += path;
  if (!cwd.endsWith('/')) {
    cwd += '/';
  }
  // Remove starting /../ segments
  cwd = cwd.replace(/^(\/\.\.\/)+/g, '/');
  // Collapse all /*/../ segments
  while (cwd.includes('/../')) {
    cwd = cwd.replace(/\/[^/]+\/\.\.\//, '/');
  }
  // Remove all /./ segments from the path
  while (cwd.includes('/./')) {
    cwd = cwd.replace(/\/\.\//, '/');
  }
  // Collapse all //
  cwd = cwd.replace(/\/{2,}/g, '/');

  // Remove trailing /
  if (cwd.endsWith('/') && cwd !== '/') {
    cwd = cwd.slice(0, -1);
  }

  return cwd;
}
