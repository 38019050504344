import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationItem } from '../../models/navigation-item.type';

export const Navigation: React.FC<
  React.PropsWithoutRef<{ navItems: NavigationItem[] }>
> = ({ navItems }) => {
  const checkBox = useRef<HTMLInputElement>(null);

  const onNavLinkClicked = () => {
    if (checkBox.current) {
      checkBox.current.click();
    }
  };

  return (
    <nav>
      <input
        ref={checkBox}
        className="nav--hamburger-checkbox"
        type="checkbox"
      />
      <div className="nav--hamburger-menu"></div>
      <ul className="nav--list">
        {navItems.map((navItem) => {
          const path = navItem.path.join('/');
          return (
            <li key={path} className="nav--list-item">
              <NavLink to={path} onClick={onNavLinkClicked}>
                {navItem.linkText}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
