import { pages } from '../../context/application';
import { OutputLine } from '../../hooks/useAppendLine';
import { PageWithParent } from '../../models/page-with-parent.type';
import { parseArgs, resolvePath } from './helpers';
import { fileCompletions } from './helpers/completion/fileCompletions';
import { Command } from './index';

type DirEntry =
  | {
      type: 'dir';
      dir: PageWithParent;
      name: string;
    }
  | {
      type: 'file';
      contents: string;
      name: string;
    };

function dirName(dir: PageWithParent) {
  return dir.pathSegments[dir.pathSegments.length - 1] ?? '';
}

export function dirContents(dir: string | PageWithParent): DirEntry[] | null {
  const page = typeof dir === 'string' ? pages.get(dir) : dir;
  if (!page) {
    return null;
  }
  return [
    {
      name: '.',
      type: 'dir',
      dir: page,
    },
    {
      name: '..',
      type: 'dir',
      dir: page.parent ?? page,
    },
    ...page.children.map(
      (child) => ({ type: 'dir', dir: child, name: dirName(child) } as const)
    ),
    {
      name: 'index.md',
      type: 'file',
      contents: page.content,
    },
    ...Object.entries(page.additionalFields).map(
      ([fieldName, fieldValue]) =>
        ({
          name: fieldName,
          type: 'file',
          contents: fieldValue.content,
        } as const)
    ),
  ];
}

export const ls: Command = {
  execute(args, cwd) {
    const [dirs, shortFlags, longFlags] = parseArgs(args);

    const showAll = shortFlags.includes('a') || longFlags.includes('all');
    const listView = shortFlags.includes('l') || longFlags.includes('list');

    if (!dirs.length) {
      dirs.push(cwd.path);
    }

    const out: OutputLine[] = [];

    for (const dir of dirs) {
      const fullPath = resolvePath(cwd.path, dir);
      let contents = dirContents(fullPath);
      if (!contents) {
        out.push({ text: `${dir}: no such directory`, stream: 'err' });
        continue;
      }
      if (dirs.length > 1) {
        out.push({ text: `${dir}:` });
      }
      if (!showAll) {
        contents = contents.filter((item) => !item.name.startsWith('.'));
      }
      if (listView) {
        out.push({ text: `total ${contents.length}` });
        for (const item of contents) {
          const size =
            item.type === 'dir'
              ? dirContents(item.dir)?.length ?? 0
              : item.contents.length;
          const line = `${
            item.type === 'dir' ? 'd' : '-'
          }rwxr-x--- 1 yatah yatah ${String(size).padStart(
            6,
            ' '
          )} Nov 24 04:22 ${item.name}`;
          out.push({ text: line });
        }
      } else {
        for (const item of contents) {
          if (item.type === 'dir') {
            out.push({ text: item.name + '/' });
          } else {
            out.push({ text: item.name });
          }
        }
      }
      if (dirs.length > 1) {
        out.push({ text: '' });
      }
    }

    return { out };
  },
  completionOptions(_args, argToComplete, cwd) {
    return fileCompletions(argToComplete, cwd, false, 'always');
  },
  help: ['ls: list files', 'usage: ls [-l|--list] [-a|--all] [dir...]'],
};
