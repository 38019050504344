import { PageWithParent } from '../models/page-with-parent.type';

export function preparePageIndex(
  page: PageWithParent
): Map<string, PageWithParent> {
  const result = new Map<string, PageWithParent>();
  addPage(page, result);

  return result;
}

function addPage(
  page: PageWithParent,
  result: Map<string, PageWithParent>
): void {
  const key = page.path;
  result.set(key, page);

  page.children.forEach((child) => addPage(child, result));
}
