import { parseArgs } from './helpers/parseArgs';
import { Command } from './index';

export const echo: Command = {
  execute(args) {
    const [options, shortFlags, longFlags] = parseArgs(args);
    if (shortFlags.includes('n') || longFlags.includes('new-line')) {
      return {
        out: options.map((text) => ({ text })),
      };
    }
    return {
      out: [{ text: options.join(' ') }],
    };
  },
  help: ['echo: print arguments', 'usage: echo [-n|--new-line] [arguments...]'],
};
