export default {
  "home": {
    "header": {
      "title": "Software-Boutique in Zürich",
      "linkText": "yatah"
    },
    "pathSegments": [],
    "children": [
      {
        "header": {
          "title": "Unsere Arbeiten & Kunden",
          "linkText": "Arbeiten"
        },
        "pathSegments": [
          "works"
        ],
        "children": [
          {
            "header": {
              "title": "Swisscom Order Fulfillment - Angular App",
              "linkText": "Swisscom Order Fulfillment - Angular App"
            },
            "pathSegments": [
              "works",
              "swisscom-order"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/swisscom-order/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/swisscom-order/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Blue TV - Multi-Platform Angular App",
              "linkText": "Blue TV - Multi-Platform Angular App"
            },
            "pathSegments": [
              "works",
              "swisscom-blue-tv"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/swisscom-blue-tv/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/swisscom-blue-tv/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Peter Moor Architekten - Website",
              "linkText": "Peter Moor Architekten - Website"
            },
            "pathSegments": [
              "works",
              "peter-moor-architekten"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/peter-moor-architekten/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/peter-moor-architekten/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Herren Globus - Intereaktiver Spiegel",
              "linkText": "Herren Globus - Intereaktiver Spiegel"
            },
            "pathSegments": [
              "works",
              "interactive-mirror"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/interactive-mirror/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/interactive-mirror/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "swisscom.ch - Software Consulting",
              "linkText": "swisscom.ch - Software Consulting"
            },
            "pathSegments": [
              "works",
              "swisscom-portal"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/swisscom-portal/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/swisscom-portal/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Lightsphere - Website",
              "linkText": "Lightsphere - Website"
            },
            "pathSegments": [
              "works",
              "lightsphere"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/lightsphere/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/lightsphere/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Totes M’Notes - iPad App",
              "linkText": "Totes M’Notes - iPad App"
            },
            "pathSegments": [
              "works",
              "totes-m-notes"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/totes-m-notes/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/totes-m-notes/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Idee und Klang Studio - Website",
              "linkText": "Idee und Klang Studio - Website"
            },
            "pathSegments": [
              "works",
              "idee-und-klang"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/idee-und-klang/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/idee-und-klang/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Kuoni - iPad App",
              "linkText": "Kuoni - iPad App"
            },
            "pathSegments": [
              "works",
              "kuoni"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/kuoni/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/kuoni/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Pensionskasse Bosch - Website",
              "linkText": "Pensionskasse Bosch - Website"
            },
            "pathSegments": [
              "works",
              "pk-bosch"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/pk-bosch/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/pk-bosch/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Occupant Entertainment - Website",
              "linkText": "Occupant Entertainment - Website"
            },
            "pathSegments": [
              "works",
              "occupant"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/occupant/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/occupant/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Küng Sauna - Interaktiver Infostand",
              "linkText": "Küng Sauna - Interaktiver Infostand"
            },
            "pathSegments": [
              "works",
              "kueng-sauna"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/kueng-sauna/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/kueng-sauna/hero.md.html')
              }
            }
          },
          {
            "header": {
              "title": "Maurice Lacroix - Micro-Site",
              "linkText": "Maurice Lacroix - Micro-Site"
            },
            "pathSegments": [
              "works",
              "maurice-lacroix"
            ],
            "children": [],
            "content": require('bundle-text:../src/home/works/maurice-lacroix/_.md.html'),
            "additionalFields": {
              "hero": {
                "content": require('bundle-text:../src/home/works/maurice-lacroix/hero.md.html')
              }
            }
          }
        ],
        "content": require('bundle-text:../src/home/works/_.md.html'),
        "additionalFields": {}
      },
      {
        "header": {
          "title": "Unser Team",
          "linkText": "Team"
        },
        "pathSegments": [
          "team"
        ],
        "children": [],
        "content": require('bundle-text:../src/home/team/_.md.html'),
        "additionalFields": {}
      },
      {
        "header": {
          "title": "Unsere Dienstleistungen & Angebot",
          "linkText": "Angebot"
        },
        "pathSegments": [
          "services"
        ],
        "children": [],
        "content": require('bundle-text:../src/home/services/_.md.html'),
        "additionalFields": {}
      },
      {
        "header": {
          "title": "So erreichen Sie uns",
          "linkText": "Kontakt"
        },
        "pathSegments": [
          "contact"
        ],
        "children": [],
        "content": require('bundle-text:../src/home/contact/_.md.html'),
        "additionalFields": {}
      }
    ],
    "content": require('bundle-text:../src/home/_.md.html'),
    "additionalFields": {}
  },
  "globals": {
    "cli-tag-line": {
      "content": require('bundle-text:../src/globals/cli-tag-line.md.html')
    },
    "tag-line": {
      "content": require('bundle-text:../src/globals/tag-line.md.html')
    }
  }
};