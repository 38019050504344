import { NavigationItem } from '../models/navigation-item.type';
import { Page } from '../models/page';
import { PageWithParent } from '../models/page-with-parent.type';

export function prepareMainNavigation(page: PageWithParent): NavigationItem[] {
  return [page, ...page.children].map((page: Page): NavigationItem => {
    return {
      ...page.header,
      path: page.pathSegments,
    };
  });
}
