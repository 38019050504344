import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Logo } from './components/Logo';
import { Switcher } from './components/Switcher';
import { ModeContext } from './context/mode';
import { Mode } from './models/mode.type';
import { router } from './routes/router';

const SESSION_MODE_KEY = 'ch.yatah.mode';

export default function App() {
  const modeFromSession = getSessionStorageMode();
  const [mode, setMode] = useState<Mode>(modeFromSession ?? 'PLAIN');

  function switchMode(mode: Mode): void {
    setMode(mode);
    localStorage.setItem(SESSION_MODE_KEY, mode);
  }

  useEffect(() => {
    document.body.dataset['mode'] = mode;
  }, [mode]);

  return (
    <ModeContext.Provider value={[mode, switchMode]}>
      <Logo></Logo>
      <RouterProvider router={router} />
      <Switcher
        mode={mode}
        onSwitchMode={switchMode}
        hasNeverBeenClicked={modeFromSession === null}
      ></Switcher>
    </ModeContext.Provider>
  );
}

function getSessionStorageMode(): Mode | null {
  return (localStorage.getItem(SESSION_MODE_KEY) as Mode) ?? null;
}
