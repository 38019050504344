export function updatePrompt(
  inp: HTMLSpanElement,
  prefix: string,
  suffix = ''
) {
  while (inp.firstChild) {
    inp.removeChild(inp.firstChild);
  }
  const textNode = document.createTextNode(prefix + suffix);
  inp.appendChild(textNode);

  const selection = window.getSelection()!;
  selection.removeAllRanges();
  const range = document.createRange();
  range.setStart(textNode, prefix.length);
  selection.addRange(range);
}
