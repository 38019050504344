export function splitArgs(
  args: string,
  variables: Record<string, string> = {}
): string[] {
  const result = [];
  let quoteMode: '"' | "'" | undefined;
  let currentWord: string | undefined;
  while (args.length) {
    let char = args[0]!;
    args = args.slice(1);
    if (char === quoteMode) {
      // Finish quoting
      quoteMode = undefined;
      continue;
    }
    if ((char === '"' || char === "'") && !quoteMode) {
      quoteMode = char;
      currentWord = currentWord || ''; // Quoted args can be empty strings
      continue;
    }
    if (!quoteMode && /\s/.test(char)) {
      result.push(currentWord);
      currentWord = undefined;
      continue;
    }
    if (char === '\\') {
      // Start an escape sequence, use next character
      char = args[0] ?? '';
      args = args.slice(1);
    } else if (char === '$' && quoteMode !== "'") {
      // Found a variable expansion
      let varName: string | undefined;
      if (args[0] === '{') {
        const index = args.indexOf('}');
        if (index > -1) {
          varName = args.slice(1, index);
          args = args.slice(index + 1);
        }
      } else {
        // Search plain variable, ends at next word boundary
        const match = args.match(/^.+?(\b|$)/);
        if (match) {
          varName = match[0];
          args = args.slice(varName.length);
        }
      }
      if (varName !== undefined) {
        char = variables[varName] ?? '';
      }
    }
    currentWord = (currentWord || '') + char;
  }
  result.push(currentWord);

  return result.filter((word) => word !== undefined) as string[];
}
