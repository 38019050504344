export function parseArgs(
  args: string[]
): [options: string[], shortFlags: string, longFlags: string[]] {
  const options = args.filter((arg) => !arg.startsWith('-'));
  const flags = args.filter((arg) => arg.startsWith('-'));
  const shortFlags = flags
    .filter((flag) => !flag.startsWith('--'))
    .join('')
    .replace(/\-/g, '');
  const longFlags = flags
    .filter((flag) => flag.startsWith('--'))
    .map((flag) => flag.substring(2));

  return [options, shortFlags, longFlags];
}
