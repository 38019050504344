import {
  easeInCubic,
  easeInExpo,
  easeInOutCubic,
  easeInOutQuad,
  easeInOutQuart,
  easeInOutSine,
  easeInQuad,
  easeInQuart,
  easeInSine,
} from './easeing';

export function randomFillColor(): string | CanvasGradient | CanvasPattern {
  const randomL: number = randomFloatBetween(40, 85);
  const randomA: number = randomIntBetween(-128, 127);
  const randomB: number = randomIntBetween(-128, 127);

  const [red, green, blue] = lab2rgb([randomL, randomA, randomB]);

  return `rgb(${red}, ${green}, ${blue})`;
}

export function randomIntBetween(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function randomFloatBetween(min: number, max: number): number {
  return Math.random() * (max - min + 1) + min;
}

const easeInFuncs: ((x: number) => number)[] = [
  easeInCubic,
  easeInOutCubic,
  easeInExpo,
  easeInQuad,
  easeInOutQuad,
  easeInQuart,
  easeInOutQuart,
  easeInSine,
  easeInOutSine,
];
export function randomEasingFunc(): (x: number) => number {
  const randomIndex = Math.floor(Math.random() * easeInFuncs.length);
  const randomEaseInFunc = easeInFuncs[randomIndex] ?? easeInQuart;

  return randomEaseInFunc;
}

export function lab2rgb([L, a, b]: [number, number, number]): [
  R: number,
  G: number,
  B: number
] {
  let y = (L + 16) / 116;
  let x = a / 500 + y;
  let z = y - b / 200;

  x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
  y = 1.0 * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787);
  z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

  let R = x * 3.2406 + y * -1.5372 + z * -0.4986;
  let G = x * -0.9689 + y * 1.8758 + z * 0.0415;
  let B = x * 0.0557 + y * -0.204 + z * 1.057;

  R = R > 0.0031308 ? 1.055 * Math.pow(R, 1 / 2.4) - 0.055 : 12.92 * R;
  G = G > 0.0031308 ? 1.055 * Math.pow(G, 1 / 2.4) - 0.055 : 12.92 * G;
  B = B > 0.0031308 ? 1.055 * Math.pow(B, 1 / 2.4) - 0.055 : 12.92 * B;

  return [
    Math.max(0, Math.min(1, R)) * 255,
    Math.max(0, Math.min(1, G)) * 255,
    Math.max(0, Math.min(1, B)) * 255,
  ];
}
