import { useCallback, useState, ReactElement } from 'react';
import { siteInfo } from '../context/application';

export interface OutputLine {
  text?: string | ReactElement<any, any>;
  html?: string;
  stream?: 'out' | 'err';
  cmd?: string;
  completion?: true;
}

export function useAppendLines() {
  const [lines, setLines] = useState<OutputLine[]>([
    { html: siteInfo['cli-tag-line'].content },
  ]);

  const appendLines = useCallback(function appendLines(
    ...appendage: OutputLine[]
  ) {
    setLines((lines) => [...lines, ...appendage]);
  },
  []);

  return [lines, appendLines] as const;
}
