import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { updatePrompt } from '../../data/commands/helpers/updatePrompt';
import { useAppendLines } from '../../hooks/useAppendLine';
import { useCommandHistory } from '../../hooks/useCommandHistory';
import { useHandleCommand } from '../../hooks/useHandleCommand';
import { PageWithParent } from '../../models/page-with-parent.type';
import { Output } from './Output';

export const TechLayout: React.FC = () => {
  const cwd = useLoaderData() as PageWithParent;
  const input = useRef<HTMLSpanElement>(null);

  const [historyItem, navigateHistory, pushToHistory] = useCommandHistory();
  const [lines, appendLines] = useAppendLines();
  const [variables, setVariables] = useState<Record<string, string>>({
    '?': '0',
    PS1: '$',
    PWD: cwd.path,
  });

  const [handleCommand, completeCommand] = useHandleCommand(
    cwd,
    variables,
    appendLines,
    setVariables
  );

  useEffect(() => {
    setVariables((vars) => ({
      ...vars,
      PWD: cwd.path,
    }));
  }, [cwd]);

  useLayoutEffect(() => {
    input.current?.focus();
  });

  useEffect(() => {
    // Input item from history
    const inp = input.current;
    if (inp) {
      updatePrompt(inp, historyItem ?? '');
    }
  }, [historyItem]);

  const prompt = `${variables['PWD']} ${variables['PS1']} `;

  return (
    <div
      role="main"
      className="content"
      onKeyDown={() => input.current?.focus()}
    >
      <Output lines={lines} />
      <span className="prompt">{prompt}</span>
      <span
        className="input"
        contentEditable
        inputMode="text"
        autoCapitalize="none"
        tabIndex={1}
        spellCheck={false}
        ref={input}
        onKeyDown={(event) => {
          const inp = input.current;
          if (!inp) {
            return;
          }
          if (event.key === 'Enter') {
            event.preventDefault();
            const cmdLine = inp.textContent ?? '';
            if (cmdLine) {
              pushToHistory(cmdLine);
            }
            inp.textContent = '';
            appendLines({
              text: (
                <>
                  <span className="prompt">{prompt}</span>
                  <span className="command">{cmdLine}</span>
                </>
              ),
            });
            handleCommand(cmdLine);
          } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            navigateHistory(-1);
          } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            navigateHistory(1);
          } else if (event.key === 'Tab') {
            event.preventDefault();
            // FIXME: Only use text up to selection
            const selection = window.getSelection();
            let range = selection?.getRangeAt(0);
            if (
              !range ||
              range.startContainer !== range.endContainer ||
              range.startOffset !== range.endOffset
            ) {
              // Not something we can handle
              return;
            }
            const offset =
              range.commonAncestorContainer.nodeType == Node.TEXT_NODE
                ? range.startOffset
                : 0;
            const cmdLine = inp.textContent ?? '';
            const completedPart = cmdLine.substring(0, offset);
            const insertion = completeCommand(completedPart);
            if (insertion) {
              updatePrompt(
                inp,
                cmdLine.substring(0, offset) + insertion,
                cmdLine.substring(offset)
              );
            }
          }
        }}
      ></span>
    </div>
  );
};
