import { Command } from './index';

import type { Mode } from '../../models/mode.type';

const MODES = ['PLAIN', 'FUN', 'TECH'];

export const mode: Command = {
  execute(args) {
    const [newMode] = args;
    if (!newMode) {
      return {
        out: [{ text: this.help[1]!, stream: 'err' }],
      };
    }
    const newModeNormalized = newMode.toUpperCase() as Mode;
    if (!MODES.includes(newModeNormalized)) {
      return {
        out: [{ text: `No such mode ${newMode}`, stream: 'err' }],
      };
    }
    if (newModeNormalized === 'TECH') {
      return {
        out: [{ text: 'Staying in tech mode. Good for you.' }],
      };
    }

    return { newMode: newModeNormalized };
  },
  completionOptions(args) {
    if (args.length) {
      // Only one arg allowed
      return [];
    }
    return MODES.map((mode) => mode.toLowerCase());
  },
  help: ['mode: switch website mode', 'usage: mode [plain|fun|tech]'],
};
