export const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h1>404</h1>
      <p>Page not found</p>
      <p>
        <a href="/">yatah.ch</a>
      </p>
    </div>
  );
};
