import React from 'react';
import { Mode } from '../models/mode.type';

type SwitcherProps = {
  mode: Mode;
  onSwitchMode: (mode: Mode) => void;
  hasNeverBeenClicked: boolean;
};

export const Switcher: React.FC<React.PropsWithoutRef<SwitcherProps>> = ({
  mode,
  onSwitchMode,
  hasNeverBeenClicked,
}) => {
  return (
    <ul
      className={`switcher mode--${mode.toLowerCase()} ${
        hasNeverBeenClicked ? 'stay-open' : ''
      }`}
    >
      <li
        className={`${mode === 'PLAIN' ? 'active' : ''}`}
        onClick={() => onSwitchMode('PLAIN')}
      >
        Plain
      </li>
      <li
        className={`${mode === 'FUN' ? 'active' : ''}`}
        onClick={() => onSwitchMode('FUN')}
      >
        Fun
      </li>
      <li
        className={`${mode === 'TECH' ? 'active' : ''}`}
        onClick={() => onSwitchMode('TECH')}
      >
        Nerd
      </li>
    </ul>
  );
};
