import { CanvasConfig } from './models/CanvasConfig.type';
import { Scene } from './models/Scene.interface';

export class Canvas {
  private scenes: Scene[] = [];
  private resizeHandler: (() => void) | null = null;

  public get context(): CanvasRenderingContext2D {
    if (!this.canvas.getContext('2d')) {
      throw new Error('Can not retrieve Context');
    }

    return this.canvas.getContext('2d', { alpha: false })!;
  }

  constructor(
    private canvas: HTMLCanvasElement,
    private config: CanvasConfig = { height: 'fullHeight', width: 'fullWidth' }
  ) {
    this.setupCanvas(this.config);
  }

  public start(scene: Scene): void {
    this.scenes.push(scene);
    scene.setup(this.context);
    scene.start();
  }

  public stopAll(): void {
    for (let scene of this.scenes) {
      scene.stop();
    }
  }

  public setDimensions(
    height: number | 'fullHeight',
    width: number | 'fullWidth'
  ) {
    this.canvas.width = width === 'fullWidth' ? window.innerWidth : width;
    this.canvas.height = height === 'fullHeight' ? window.innerHeight : height;
  }

  public destroy(): void {
    if (this.resizeHandler) {
      window.removeEventListener('resize', this.resizeHandler);
    }

    for (let scene of this.scenes) {
      scene.destroy();
    }

    this.scenes = [];
  }

  private setupCanvas(config: CanvasConfig): void {
    if (config.width == 'fullWidth' || config.height === 'fullHeight') {
      this.resizeHandler = () => {
        this.setDimensions(config.height, config.width);
      };
      window.addEventListener('resize', this.resizeHandler);
    }

    this.setDimensions(config.height, config.width);
  }
}
