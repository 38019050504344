import { createBrowserRouter } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { PageNotFound } from '../components/PageNotFound';
import { pages } from '../context/application';

export const router = createBrowserRouter([
  {
    path: '*',
    element: <Layout></Layout>,
    loader: ({ params }) => {
      const currentPage = pages.get('/' + params['*']!);
      if (!currentPage) {
        throw new Response('Page not Found', { status: 404 });
      }

      document.title = `${
        currentPage.header.title ?? ''
      } | yatah Gmbh - interactive design & development`;
      return currentPage;
    },
    errorElement: <PageNotFound></PageNotFound>,
  },
]);
