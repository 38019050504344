import { useRef, useState } from 'react';

export function useCommandHistory() {
  const history = useRef<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  const historyItem = history.current[historyIndex] ?? undefined;

  function navigateHistory(delta: 1 | -1) {
    setHistoryIndex((idx) =>
      Math.min(history.current.length - 1, Math.max(-1, idx + delta))
    );
  }

  function pushToHistory(item: string) {
    history.current.unshift(item);
    if (historyIndex > -1) {
      setHistoryIndex(-1);
    }
  }

  return [historyItem, navigateHistory, pushToHistory] as const;
}
