import React, { useEffect, useRef } from 'react';
import { BubbleScene } from './canvas/BubbleScene';
import { Canvas } from './canvas/Canvas';

export const FunLayout = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas: Canvas = new Canvas(canvasRef.current!);
    canvas.start(new BubbleScene());

    return () => {
      canvas.destroy();
    };
  }, [canvasRef]);

  return <canvas className="fun--canvas" ref={canvasRef}></canvas>;
};
