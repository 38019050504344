import { Command } from './index';

export const env: Command = {
  execute(_args, _cwd, variables) {
    const out = Object.entries(variables).map(([name, value]) => ({
      text: `${name}=${value}`,
    }));
    return {
      out,
    };
  },
  help: ['env: list environment variables', 'usage: env'],
};
